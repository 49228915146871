import React, { memo } from 'react'

const FooterComponent = memo(() => {
  return (
    <footer className='py-3'>
      <div className="container">
        <div className='row g-3'>
          <hr className='col-12' />
          
          <div className='col-12 text-center'>
            <small>Version: <b>{process.env.REACT_APP_VERSION}</b></small>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default FooterComponent