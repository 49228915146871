import { useEffect } from 'react';

const MetaTagComponent = () => {
  useEffect(() => {
    const metaName = 'apple-itunes-app';
    const existingMeta = document.querySelector(`meta[name="${metaName}"]`);

    if (!existingMeta) {
      const meta = document.createElement('meta');
      meta.name = metaName;
      meta.content = 'app-id=1553982012, app-argument=https://phonetestapp.com';
      document.head.appendChild(meta);
      
      return () => {
        document.head.removeChild(meta);
      };
    } else {
      existingMeta.content = 'app-id=1553982012, app-argument=https://phonetestapp.com';
    }
  }, []);

  return null;
};

export default MetaTagComponent;