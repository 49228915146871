import React, { useEffect, useState, useCallback, memo } from 'react';
import logo from '../img/logos/logo.png';
import AppStoreBadgeUS from '../img/App_Store_Badge_US.svg';
import { useCookies } from 'react-cookie';
import { QRious } from 'react-qrious';
import { useAuth } from '../context/AuthContext';

const HeaderComponent = memo(() => {
  const { user, logout, loginWithCredential } = useAuth();
  const [qr, setQr] = useState('https://phonetest.app');
  const [cookies, removeCookie] = useCookies(['hoplaCredentials']);

  const redirectLogin = useCallback(() => {
    removeCookie('hoplaCredentials');
    const { href } = window.location;
    const domain = href.includes('localhost') ? "http://localhost:3000" : "https://phonetest.app";
    window.open(domain, '_self');
  }, [removeCookie]);

  const handleAuthStateChanged = useCallback(async (currentUser) => {
    const credential = cookies.hoplaCredentials;
    if (currentUser) {
      if (credential === undefined || typeof credential !== 'object') {
        setQr('https://phonetest.app');
      } else {
        setQr(`https://phonetest.app/?ref=${currentUser.uid}`);
      }
    } else {
      if (credential === undefined || typeof credential !== 'object') {
        redirectLogin();
      } else {
        try {
          console.log('Attempting login with saved credentials:', credential);

          const result = await loginWithCredential(credential);
          console.log('Logged in user with saved credentials:', result.user);
          setQr(`https://phonetest.app/?ref=${result.user.uid}`);
        } catch (error) {
          console.error('Error during login with saved credentials:', error);
          redirectLogin();
        }
      }
    }
  }, [cookies.hoplaCredentials, loginWithCredential, redirectLogin]);

  const goToHome = () => {
    const { href } = window.location;
    const domain = href.includes('localhost') ? "http://localhost:3000/portal" : "https://portal.phonetest.app";
    window.open(domain, '_self');
  };

  const handleLogout = async () => {
    try {
      await logout();
      redirectLogin();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleMenu = () => {
    const dropdownMenu = document.querySelector('.hopla-dropdown-menu');
    dropdownMenu.style.maxHeight = dropdownMenu.style.maxHeight === '0px' || dropdownMenu.style.maxHeight === '' ? `${dropdownMenu.scrollHeight}px` : '0px';
  };

  useEffect(() => {
    if (user) {
      handleAuthStateChanged(user);
    }
  }, [user, handleAuthStateChanged]);

  return (
    <header id="header" className="header dark bg-dark sticky-top">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-dark bg-dark navbar-expand-md">
              <div className="container-fluid p-0">
                <div className="navbar-brand" onClick={goToHome}>
                  <img loading="lazy" src={logo} alt="Logo" height="40" />
                </div>

                {!user ? (
                  <div className="not-loggedin" style={{ marginLeft: 'auto' }}>
                    <div className="d-flex align-self-center justify-content-end align-items-center gap-3">
                      <a className="text-center" href="https://apps.apple.com/us/app/phonetest-app/id1553982012">
                        <img src={AppStoreBadgeUS} alt="App Store Badge" height="38px" />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div id="item-name-avatar" style={{ marginLeft: 'auto' }} className="dropdown item-name-avatar is-loggedin" onClick={toggleMenu} >
                    <div className="dropbtn loggedin">
                      <p className="username mb-0 order-1 ">{user.displayName}</p>
                      <div className="image avatar order-2" style={{ marginLeft: '.5em' }}>
                        <img src={user.photoURL || './img/profile-picture.webp'} alt="Profile" title="Profile image" />
                      </div>
                      <div className="arrow">
                        <div id="nav-icon">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <ul className="hopla-dropdown-menu">
                      <li className="hopla-my-qr">
                        <QRious size={500} value={qr} className="img-fluid p-2" />
                      </li>
                      <li className="hopla-logout">
                        <a id="hopla-logout" className="hopla-dropdown-item logout" href="#logout" onClick={(e) => { e.preventDefault(); handleLogout(); }}>Logout</a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
})

export default HeaderComponent;