import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { TbDirectionArrows } from "react-icons/tb";
import { LuRotate3D } from "react-icons/lu";
import { RiBodyScanFill } from "react-icons/ri";
import { Pagination } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import VibrationIcon from '@mui/icons-material/Vibration';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SpeedIcon from '@mui/icons-material/Speed';
import ExploreIcon from '@mui/icons-material/Explore';
import SensorsIcon from '@mui/icons-material/Sensors';
import MicIcon from '@mui/icons-material/Mic';
import ImageIcon from '@mui/icons-material/Image';
import placeholderImage from '../img/placeholder-image.webp';
import BugIcon from './BugIcon';

export const InfoCard = ({ icon, title, data, imageURL, currentIndex, total, setCurrentIndex }) => {
  const [imageError, setImageError] = useState(false);

  const renderIcon = () => {
    if (!icon) return null;

    switch (icon) {
      case "InfoIcon": return <InfoIcon className='text-muted me-2' />;
      case "StorageIcon": return <StorageIcon className='text-muted me-2' />;
      case "microchip": return <FontAwesomeIcon icon="fa-solid fa-microchip" className='text-muted me-2' size="lg"  />;
      case "MemoryIcon": return <MemoryIcon className='text-muted me-2' />;
      case "ChargingStationIcon": return <ChargingStationIcon className='text-muted me-2' />;
      case "VibrationIcon": return <VibrationIcon className='text-muted me-2' />;
      case "FlashlightOnIcon": return <FlashlightOnIcon className='text-muted me-2' />;
      case "VolumeUpIcon": return <VolumeUpIcon className='text-muted me-2' />;
      case "PowerSettingsNewIcon": return <PowerSettingsNewIcon className='text-muted me-2' />;
      case "NotificationsOffIcon": return <NotificationsOffIcon className='text-muted me-2' />;
      case "BluetoothIcon": return <BluetoothIcon className='text-muted me-2' />;
      case "SignalCellularAltIcon": return <SignalCellularAltIcon className='text-muted me-2' />;
      case "WifiIcon": return <WifiIcon className='text-muted me-2' />;
      case "ScreenshotIcon": return <ScreenshotIcon className='text-muted me-2' />;
      case "TouchAppIcon": return <TouchAppIcon className='text-muted me-2' />;
      case "SpeedIcon": return <SpeedIcon className='text-muted me-2' />;
      case "direction": return <TbDirectionArrows className='text-muted me-2' size={24} />;
      case "gyroscope": return <LuRotate3D className='text-muted me-2' size={24} />;
      case "ExploreIcon": return <ExploreIcon className='text-muted me-2' />;
      case "SensorsIcon": return <SensorsIcon className='text-muted me-2' />;
      case "authentication": return <RiBodyScanFill className='text-muted me-2' size={24} />;
      
      default:
        return <FontAwesomeIcon icon={icon} className='text-muted me-2' size="lg" />;
    }
  };

  const createAccordionHTML = (imageURL, accordionId) => {
    const isCameraAccordion = accordionId.toLowerCase().includes("camera");

    return (
      <Fragment>
        {imageURL &&
          <div className="accordion accordion-flush" id={accordionId}>
            <div className="accordion-item">
              <h2 className="accordion-header" id={accordionId + "headingOne"}>
                <button
                  className={`accordion-button${isCameraAccordion ? "" : " collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#" + accordionId + "-collapseOne"}
                  aria-expanded={isCameraAccordion ? "true" : "false"}
                  aria-controls={accordionId + "-collapseOne"}>
                  <ImageIcon className='text-muted me-2' /> View Image
                </button>
              </h2>
              <div
                id={accordionId + "-collapseOne"}
                className={`accordion-collapse collapse${isCameraAccordion ? " show" : ""} p-2`}
                aria-labelledby={accordionId + "headingOne"}
                data-bs-parent={"#" + accordionId}>
                <div className="border p-2">
                  <img 
                    src={imageError ? placeholderImage : imageURL} 
                    className="img-fluid border border-2" 
                    style={{ maxHeight: '512px' }} 
                    alt={accordionId} 
                    onError={() => setImageError(true)} 
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  };

  const handleChange = (event, value) => {
    setCurrentIndex(value - 1);
  };

  const accordionId = title !== undefined ? `accordion${title.replace(/\s+/g, '')}` : "";
  
  return (
    <div className="col">
      <div className="container apple-card" style={{ height: '100%' }}>
        <div className="row h-100">
          <div className="col d-flex flex-column justify-content-between icon-grid-item">
            <div className="content">
              <div className="d-flex align-items-center align-self-center justify-content-between mb-3">
                <h6 className="fw-bold m-0">{renderIcon()} {title}</h6>
                <BugIcon testName={title} renderIcon={renderIcon()} />
              </div>
              
              {data.map(({ label, value }) => (
                <p key={label}><b>{label}:</b> <span>{value}</span></p>
              ))}

              {imageURL && createAccordionHTML(imageURL, accordionId)}
            </div>

            {total > 1 && <Fragment>
                <div id='pagination' className='d-flex justify-content-center mt-3'>
                  <Pagination count={total} page={currentIndex} siblingCount={0} boundaryCount={1} size="small" shape="rounded" onChange={handleChange} />
                </div>
              </Fragment>}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BasicInformationCard = ({ basicInformation }) => (
  <InfoCard
    icon="InfoIcon"
    title="Basic Information"
    data={[
      { label: 'Model', value: basicInformation?.model },
      { label: 'System', value: basicInformation?.systemVersion }
    ]}
  />
);

export const StorageCard = ({ storage }) => (
  <InfoCard
    icon="StorageIcon"
    title="Storage"
    data={[
      { label: 'Total', value: storage?.total },
      { label: 'Used', value: storage?.used },
      { label: 'Free', value: storage?.free }
    ]}
  />
);

export const CpuCard = ({ cpu }) => (
  <InfoCard
    icon="microchip"
    title="CPU"
    data={[
      { label: 'Processor Count', value: cpu?.processorCount },
      { label: 'Model', value: cpu?.model }
    ]}
  />
);

export const MemoryCard = ({ memory }) => (
  <InfoCard
    icon="MemoryIcon"
    title="Memory"
    data={[
      { label: 'Capacity', value: memory?.capacity },
      { label: 'Access Speed', value: memory?.accessSpeed }
    ]}
  />
);

const GenericInfoCard = ({ icon, title, dataItems, imageURL, threeUTools }) => {
  dataItems = dataItems ?? [];
  const lastIndex = dataItems.length ? dataItems.length - 1 : 0;
  const [currentIndex, setCurrentIndex] = useState(lastIndex);
  const currentItem = dataItems[currentIndex] || {};

  const data = [];

  if(title === "Volume Control") {
    data.push({ label: 'Up', value: <StateIcon state={currentItem.up} /> });
    data.push({ label: 'Down', value: <StateIcon state={currentItem.down} /> });
  } else {
    data.push({ label: 'State', value: <StateIcon state={currentItem.state ?? currentItem.status ?? currentItem.searchCapability} /> });
  }

  data.push(
    { label: 'Mode', value: capitalizeFirstLetter(currentItem.mode) },
    { label: 'Date', value: currentItem.timestampInMillis ? moment(currentItem.timestampInMillis).format("MMM DD, YYYY") : "-" }
  );

  if (currentItem.timestampInMillis !== undefined) {
    data.push({ label: 'Time', value: moment(currentItem.timestampInMillis).format("hh:mm:ss A") });
  }

  if (currentItem.percentage !== undefined) {
    data.push({ label: 'Percentage', value: `${currentItem.percentage.toFixed()}%` });
  }

  if (currentItem.touchPoints !== undefined) {
    data.push({ label: 'Touch points', value: currentItem.touchPoints });
  }

  if (currentItem.pressure !== undefined) {
    data.push({ label: 'Pressure', value: `${currentItem.pressure.toFixed(2)} m` });
  }

  if (currentItem.minAltitude !== undefined) {
    data.push({ label: 'Min Altitude', value: `${currentItem.minAltitude.toFixed(2)} m` });
  }

  if (currentItem.maxAltitude !== undefined) {
    data.push({ label: 'Max Altitude', value: `${currentItem.maxAltitude.toFixed(2)} m` });
  }

  if (currentItem.difference !== undefined) {
    data.push({ label: 'Difference', value: `${currentItem.difference.toFixed(2)} m` });
  }

  if (threeUTools) {
    const batterycycle = threeUTools.batterycycle ? threeUTools.batterycycle.current_value : "-";
    data.push({ label: 'Charge Times (3u)', value: batterycycle });

    const batterycapacity = threeUTools.batterycapacity ? threeUTools.batterycapacity.current_value : "-";
    data.push({ label: 'Battery Life (3u)', value: batterycapacity });
  }

  return (
    <InfoCard
      icon={icon}
      title={title}
      data={data}
      currentIndex={currentIndex + 1}
      total={dataItems.length}
      setCurrentIndex={setCurrentIndex}
      imageURL={imageURL ?? currentItem.imageURL}
    />
  );
};

export const ChargingPortCard = ({ chargingPort, threeUTools }) => {
  return <GenericInfoCard icon='ChargingStationIcon' title="Charging Port" dataItems={chargingPort} threeUTools={threeUTools} />;
};

export const VibratorCard = ({ vibrator }) => {
  return <GenericInfoCard icon='VibrationIcon' title="Vibrator" dataItems={vibrator} />;
};

export const FlashlightCard = ({ flashlight }) => {
  return <GenericInfoCard icon='FlashlightOnIcon' title="Flashlight" dataItems={flashlight} />;
};

export const VolumeControlCard = ({ volume }) => {
  return <GenericInfoCard icon='VolumeUpIcon' title="Volume Control" dataItems={volume} />;
};

export const PowerCard = ({ power }) => {
  return <GenericInfoCard icon='PowerSettingsNewIcon' title="Power" dataItems={power} />;
};

export const SilentCard = ({ silent }) => {
  return <GenericInfoCard icon="fa-solid fa-bell-slash" title="Silent Switch" dataItems={silent} />;
};

export const BluetoothCard = ({ bluetooth }) => {
  return <GenericInfoCard icon='BluetoothIcon' title="Bluetooth" dataItems={bluetooth} />;
};

export const CellularCard = ({ cellular }) => {
  return <GenericInfoCard icon='SignalCellularAltIcon' title="Cellular" dataItems={cellular} />;
};

export const WifiCard = ({ wifi }) => {
  return <GenericInfoCard icon='WifiIcon' title="Wi-Fi" dataItems={wifi} />;
};

export const TouchScreenCard = ({ touchScreen }) => {
  return <GenericInfoCard icon='ScreenshotIcon' title="Touch Screen" dataItems={touchScreen} />;
};

export const MultiTouchCard = ({ multiTouch }) => {
  return <GenericInfoCard icon='TouchAppIcon' title="Multi Touch" dataItems={multiTouch} />;
};

export const BarometerCard = ({ barometer }) => {
  return <GenericInfoCard icon='SpeedIcon' title="Barometer" dataItems={barometer} />;
};

export const DirectionCard = ({ direction }) => {
  return <GenericInfoCard icon='direction' title="Direction Sensor" dataItems={direction} />;
};

export const GyroscopeCard = ({ gyroscope }) => {
  return <GenericInfoCard icon='gyroscope' title="Gyroscope" dataItems={gyroscope} />;
};

export const CompassCard = ({ compass }) => {
  return <GenericInfoCard icon='ExploreIcon' title="Compass" dataItems={compass} />;
};

export const ProximityCard = ({ proximity }) => {
  return <GenericInfoCard icon='SensorsIcon' title="Proximity" dataItems={proximity} />;
};

export const AuthenticationCard = ({ authentication }) => {
  const length = authentication.length ?? 0; 
  const title = length ? authentication[length - 1].type : "Authentication";
  return <GenericInfoCard icon='authentication' title={title} dataItems={authentication} />;
};

export const SpeakerCard = ({ speakerData }) => {

  const lastIndex = speakerData && speakerData.length ? speakerData.length - 1 : 0;
  const [currentIndex, setCurrentIndex] = useState(lastIndex);

  const defaultFrequencies = {
    "2400hz": { defaultValue: 2400, category: "Medium Frequency", value: 0, status: null },
    "5000hz": { defaultValue: 5000, category: "High Frequency", value: 0, status: null },
    "1200hz": { defaultValue: 1200, category: "Medium Frequency", value: 0, status: null },
    "10000hz": { defaultValue: 10000, category: "High Frequency", value: 0, status: null },
    "600hz": { defaultValue: 600, category: "Medium Frequency", value: 0, status: null },
    "440hz": { defaultValue: 440, category: "Low Frequency", value: 0, status: null },
    "7500hz": { defaultValue: 7500, category: "High Frequency", value: 0, status: null }
  };

  const formatSpeakerData = (speaker) => {
    const frequencies = speaker ? speaker.frequencies : defaultFrequencies;

    return Object.keys(frequencies)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .map(freqKey => ({
        freq: freqKey,
        value: frequencies[freqKey].value,
        status: frequencies[freqKey].status
      }));
  };

  const renderSpeakerData = (speaker) => {
    return speaker.map(({ freq, value, status }, index) => (
      <p key={index}>
        <b className="ps-4">{freq}:</b> <span>{`${Math.round(value)}hz`} <span className="ms-3"><StateIcon state={status} /></span></span>
      </p>
    ));
  };

  if (!speakerData || speakerData.length === 0) {
    const defaultSpeaker = formatSpeakerData(null);
    return (
      <div className="col">
        <div className="container-lg apple-card" style={{ height: '100%' }}>
          <div className="row">    
            <div className="col d-flex align-items-start icon-grid-item">
              <div className="content">
                <div className="d-flex align-items-center align-self-center justify-content-between">
                  <h6 className="fw-bold mb-3"><MicIcon className='me-2' /> Built-In Microphone</h6>
                  <BugIcon testName="Built-In Microphone" renderIcon={<MicIcon className='me-2' />} />
                </div>                
                <p><b>Mode:</b> <span>-</span></p>
                <p><b>Date:</b> <span>-</span></p>
              </div>
            </div>

            <div id="speakers" style={{ display: 'block' }}>
              <div className="row">
                <div className="col-12"><hr className='mt-0' /></div>

                <div id="lowerSpeaker" className="col-md-6">
                  <div className="content">
                    <h6 className="fw-bold mb-3"><VolumeUpIcon className='me-2' /> Lower Speaker</h6>
                    {renderSpeakerData(defaultSpeaker)}
                  </div>
                </div>

                <div className="col-12 d-block d-md-none"><hr /></div>

                <div id="upperSpeaker" className="col-md-6">
                  <div className="content">
                    <h6 className="fw-bold mb-3"><VolumeUpIcon className='me-2' /> Upper Speaker</h6>
                    {renderSpeakerData(defaultSpeaker)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const total = speakerData.length;

  if (currentIndex > lastIndex) {
    setCurrentIndex(lastIndex);
  }

  const currentItem = speakerData[currentIndex] || {};
  const { mode, timestampInMillis, speakers } = currentItem;

  const handleChange = (event, value) => {
    setCurrentIndex(value - 1);
  };

  return (
    <div className="col">
      <div className="container-lg apple-card" style={{ height: '100%' }}>
        <div className="row">
          <div className="col d-flex align-items-start icon-grid-item">
            <div className="content">
              <div className="d-flex align-items-center align-self-center justify-content-between mb-3">
                <h6 className="fw-bold mb-3"><MicIcon className='me-2' /> Built-In Microphone</h6>
                <BugIcon testName="Built-In Microphone" renderIcon={<MicIcon className='me-2' />} />              </div>
              <p><b>Mode:</b> <span>{mode ? capitalizeFirstLetter(mode) : '-'}</span></p>
              <p><b>Date:</b> <span>{ timestampInMillis ? moment(timestampInMillis).format("MMM DD, YYYY hh:mm:ss A") : "-"}</span></p>
            </div>
          </div>

          <div id="speakers" style={{ display: 'block' }}>
            <div className="row">
              <div className="col-12"><hr className='mt-0' /></div>

              <div id="lowerSpeaker" className="col-md-6">
                <div className="content">
                  <h6 className="fw-bold mb-3"><VolumeUpIcon className='me-2' /> {speakers?.lowerSpeaker?.name ?? "Lower Speaker"}</h6>
                  {renderSpeakerData(formatSpeakerData(speakers?.lowerSpeaker))}
                </div>
              </div>

              <div className="col-12 d-block d-md-none"><hr /></div>

              <div id="upperSpeaker" className="col-md-6">
                <div className="content">
                  <h6 className="fw-bold mb-3"><VolumeUpIcon className='me-2' /> {speakers?.upperSpeaker?.name ?? "Upper Speaker"}</h6>
                  {renderSpeakerData(formatSpeakerData(speakers?.upperSpeaker))}
                </div>
              </div>
            </div>
          </div>

          {total > 1 && <div className='col mt-3'>
            <div id='pagination' className='d-flex justify-content-center'>
              <Pagination count={total} page={currentIndex + 1} siblingCount={0} boundaryCount={1} size="small" shape="rounded" onChange={handleChange} />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export const CameraCard = ({ camera, title }) => {

  function formatKey(key) {
    const formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  }

  return <GenericInfoCard title={formatKey(title ?? "Camera")} dataItems={camera} />;
};

const defaultCamera = {
  name: "Default Camera",
  resolution: "1920x1080",
  type: "Default Type",
};

export const CameraGrid = ({ report }) => {
  const cameraObjects = filterCameraObjects(report);
  const hasCameras = Object.keys(cameraObjects).length > 0;

  return (
    <div className="col-12">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 g-4">
        {hasCameras ? (
          Object.keys(cameraObjects).map((key, index) => {
            const cameraArray = cameraObjects[key];
            return <CameraCard key={index} camera={cameraArray} title={key} />;
          })
        ) : (
          <>
            <div className="col">
              <CameraCard camera={defaultCamera} title="Front Camera" />
            </div>
            <div className="col">
              <CameraCard camera={defaultCamera} title="Back Camera"/>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function filterCameraObjects(data) {
  const cameraObjects = Object.keys(data).reduce((filtered, key) => {
    if (key.includes("Camera")) {
      filtered[key] = data[key];
    }
    return filtered;
  }, {});

  const sortedCameraObjects = {};
  const keys = Object.keys(cameraObjects).sort((a, b) => {
    if (a.includes("Front")) return -1;
    if (b.includes("Front")) return 1;
    return 0;
  });

  keys.forEach(key => {
    sortedCameraObjects[key] = cameraObjects[key];
  });

  return sortedCameraObjects
}

export const StateIcon = ({ state }) => {
  const [condition, setCondition] = useState(null);

  useEffect(() => {
    setCondition(state);
  }, [state]);

  return (
    <Fragment>
      {condition == null
        ? <FontAwesomeIcon icon="fa-solid fa-circle" className='text-primary' />
        : condition
          ? <FontAwesomeIcon icon="fa-solid fa-circle-check" className="text-success" />
          : <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className="text-warning" />
      }
    </Fragment>
  );
};

export const capitalizeFirstLetter = (string) => {
  return string !== undefined ? string?.charAt(0).toUpperCase() + string.slice(1) : '-';
};