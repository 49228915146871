import React, { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import placeholderImage from '../img/placeholder-image.webp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import moment from 'moment';
import { FaImage, FaVideo } from 'react-icons/fa6';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Pagination } from '@mui/material';

const fileNames = [
  'COSMETIC_TEST_PHOTO_IMEI.jpg',
  'COSMETIC_TEST_PHOTO_Screen_ON.jpg',
  'COSMETIC_TEST_PHOTO_Screen_OFF.jpg',
  'COSMETIC_TEST_PHOTO_Left.jpg',
  'COSMETIC_TEST_PHOTO_Top.jpg',
  'COSMETIC_TEST_PHOTO_Right.jpg',
  'COSMETIC_TEST_PHOTO_Bottom.jpg',
  'COSMETIC_TEST_PHOTO_Back.jpg',
  'COSMETIC_TEST_PHOTO_Cameras.jpg',
  'COSMETIC_TEST_VIDEO.mov'
];

const sortFilesByFileNames = (files, fileNames) => {
  return files.sort((a, b) => {
    const aFileName = decodeURIComponent(a.url).split('/').pop().split('?')[0];
    const bFileName = decodeURIComponent(b.url).split('/').pop().split('?')[0];

    const indexA = fileNames.indexOf(aFileName);
    const indexB = fileNames.indexOf(bFileName);

    if (indexA === -1 && indexB === -1) {
      return 0;
    } else if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });
};

const ManualGrading = memo(({ data = [] }) => {
  const [total, setTotal] = useState(0);
  const lastIndex = data && data.length ? data.length - 1 : 0;
  const [currentIndex, setCurrentIndex] = useState(lastIndex);

  const [imageError, setImageError] = useState(false);
  const [photoVideoData, setPhotoVideoData] = useState([]);

  const handleChange = (event, value) => {
    setCurrentIndex(value - 1);
  };

  useEffect(() => {
    setTotal(data.length);
  }, [data]);

  useEffect(() => {
    if (total > 0) {
      const lastIndex = total - 1;
      setCurrentIndex(lastIndex);
    }
  }, [total, data]);

  useEffect(() => {
    var lastData = data[currentIndex] || {};
    if (lastData.mode === "manual" && lastData.files) {
      const sortedFiles = sortFilesByFileNames(lastData.files, fileNames);
      lastData.files = sortedFiles;
    }
    setPhotoVideoData(lastData);
  }, [currentIndex, data]);

  return (
    <div className="col-12">
      {photoVideoData ? (
        <div className="row">
          <div className="col">
            <div className="container-lg apple-card" style={{ height: '100%' }}>
              <div className="row">
                <div className="col d-flex align-items-start icon-grid-item">
                  <div className="content">
                    <div className="d-flex align-items-center align-self-center justify-content-between">
                      <h6 className="fw-bold mb-3">
                        {photoVideoData.mode === "manual" ? <Fragment>Manual Grading <span className='text-muted'>({photoVideoData.reason})</span></Fragment> : <Fragment>Cosmetic Grading</Fragment>}
                      </h6>
                    </div>
                    <p><b>Date:</b> <span>{photoVideoData.timestampInMillis ? moment(photoVideoData.timestampInMillis).format("MMM DD, YYYY") : "-"}</span></p>
                    <p><b>Time:</b> <span>{photoVideoData.timestampInMillis ? moment(photoVideoData.timestampInMillis).format("hh:mm:ss A") : "-"}</span></p>

                    {photoVideoData && photoVideoData.files && photoVideoData.files.length &&
                      <div className="accordion accordion-flush" id="manualGrading">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={"manualGradingheadingOne"}>
                            <button className={`accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target={"#manualGrading-collapseOne"} aria-expanded={"true"} aria-controls={"manualGrading-collapseOne"}>
                              <PermMediaIcon className='text-muted me-2' /> Files
                            </button>
                          </h2>

                          <div id={"manualGrading-collapseOne"} className={`accordion-collapse collapse show p-2`} aria-labelledby={"manualGradingheadingOne"} data-bs-parent={"#manualGrading"}>
                            <div className="row">
                              <div className="col border p-3 bg-light">
                                <div id='files' className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4 '>
                                  {photoVideoData.files.map((file, index) => (
                                    <div key={index}>
                                      <h6 className="fw-bold mb-3">
                                        {file.type !== 'video' && <FaImage className='text-muted me-2' />}
                                        {file.type === 'video' && <FaVideo className='text-muted me-2' />}
                                        {file.name}
                                      </h6>
                                      <hr />
                                      <div style={{ position: 'relative', width: '100%', paddingBottom: '177.78%' }}>
                                        {file.type === "video" ? (
                                          <video
                                            className="bg-vid border p-1"
                                            src={file.url}
                                            controls
                                            style={{
                                              position: 'absolute',
                                              top: 0,
                                              left: 0,
                                              width: '100%',
                                              height: '100%',
                                              objectFit: 'cover'
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imageError[index] ? placeholderImage : file.url}
                                            className="img-fluid border p-1"
                                            alt={file.name}
                                            onError={() => setImageError(index)}
                                            style={{
                                              position: 'absolute',
                                              top: 0,
                                              left: 0,
                                              width: '100%',
                                              height: '100%',
                                              objectFit: 'cover'
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                  </div>
                </div>

                <div className="col-12">
                  {total > 1 && <div className='col mt-3'>
                    <div id='pagination' className='d-flex justify-content-center'>
                      <Pagination count={total} page={currentIndex + 1} siblingCount={0} boundaryCount={1} size="small" shape="rounded" onChange={handleChange} />
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-lg apple-card">
          <div className="row">
            <div className="col">
              <p className='m-0'><ContentPasteSearchIcon className='me-2' />The device still not has classification data by photo or video.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

ManualGrading.propTypes = {
  data: PropTypes.array,
};

export default ManualGrading;