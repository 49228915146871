import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithCredential
} from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useCookies } from "react-cookie";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line 
  const [cookies, removeCookie] = useCookies(['hoplaCredentials']);
  const isInitialLoad = useRef(true); // Use useRef to store isInitialLoad flag

  const signup = async (email, password) => {
    try {
      return await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      googleProvider.setCustomParameters({
        prompt: 'select_account'
      });
      return await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error("Error logging in with Google:", error);
      throw error;
    }
  };

  const loginWithCredential = async (credential) => {
    try {
      const authCredential = GoogleAuthProvider.credential(credential.idToken);
      return await signInWithCredential(auth, authCredential);
    } catch (error) {
      console.error("Error logging in with credential:", error);
      throw error;
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      removeCookie('hoplaCredentials');
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email) => {
    try {
      return await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const updateLastLogin = async (user) => {
    try {
      const { uid, email, displayName, photoURL } = user;
      const userRef = doc(db, "users", uid);

      await setDoc(userRef, {
        lastLogin: serverTimestamp(),
        displayName,
        email,
        photoURL,
        uid
      }, { merge: true });
    } catch (error) {
      console.error("Error updating last login timestamp:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      const credential = cookies.hoplaCredentials;
      console.log({ currentUser, credential });

      if (isInitialLoad.current && currentUser === null && credential !== undefined && typeof credential === 'object') {
        try {
          const result = await loginWithCredential(credential);
          console.log('Logged in user with saved credentials:', result.user);
          setUser(result.user);
        } catch (error) {
          console.error('Error logging in with saved credentials:', error);
          setUser(null);
        } finally {
          setLoading(false);
          isInitialLoad.current = false;
        }
      } else  if(credential === undefined || typeof credential !== 'object') {
        await logout();
      } else {
        setUser(currentUser);
        setLoading(false);
        isInitialLoad.current = false;
        if (currentUser) updateLastLogin(currentUser);
      }
    });

    return () => unsubscribe();
    
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        loginWithGoogle,
        resetPassword,
        loginWithCredential,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}