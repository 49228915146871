import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import placeholderImage from '../img/placeholder-image.webp';

const BugIcon = ({ testName, renderIcon }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (total > 0) {
      setCurrentIndex(total - 1);
    }
  };
  const handleShow = () => setShow(true);

  const [total, setTotal] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [matchingBugs, setMatchingBugs] = useState([]);

  const [filesURL, setFilesURL] = useState("");
  const [currentBug, setCurrentBug] = useState(null);
  const [fadeClass, setFadeClass] = useState("show");

  const handleChange = (event, value) => {
    setCurrentIndex(value - 1);
  };

  useEffect(() => {
    const bugs = window.bugsReport ?? [];
    const normalizedTestName = testName ? testName.toUpperCase() : '';
    const testBugs = bugs.filter(bug =>
      bug.testName && bug.testName.toUpperCase() === normalizedTestName
    );
    setMatchingBugs(testBugs);
  }, [testName]);

  useEffect(() => {
    setTotal(matchingBugs.length);
  }, [matchingBugs]);

  useEffect(() => {
    if (total > 0) {
      const lastIndex = total - 1;
      setCurrentIndex(lastIndex);
    }
  }, [total, matchingBugs]);

  useEffect(() => {
    setFadeClass("");
    const items = matchingBugs[currentIndex] ?? [];
    setCurrentBug(items);

    setTimeout(() => {
      setFadeClass("show");
    }, 1000);
  }, [currentIndex, matchingBugs]);

  useEffect(() => {
    if (currentBug) {
      const filesURLs = currentBug.filesURLs ?? [];
      const url = filesURLs.length ? filesURLs[0] : "";
      setFilesURL(url);
    }
  }, [currentBug]);

  const renderFile = (url) => {
    const cleanUrl = url.split('?')[0];
    const extension = cleanUrl.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    const isVideo = ['mp4', 'webm', 'ogg'].includes(extension);

    if (isImage) {
      return <img src={url} className="img-fluid border border-2" style={{ maxHeight: '320px' }} alt="bug-related" />;
    } else if (isVideo) {
      return <video className="bg-vid" src={url} controls style={{ maxHeight: '320px' }} />;
    } else {
      return <></>;
    }
  };

  return matchingBugs.length > 0 ? (
    <Fragment>
      <FontAwesomeIcon icon="fa-solid fa-bug" className='text-danger ms-auto' onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="md">
        <ModalBody>
          <div className="row">
            <div className="col d-flex align-items-start icon-grid-item">
              <div className="content">
                <div className="d-flex align-items-center align-self-center justify-content-between mb-3">
                  <h6 className="fw-bold m-0">
                    {renderIcon}{testName} (Bugs)
                  </h6>
                  <FontAwesomeIcon icon="fa-solid fa-xmark" className='ms-auto' onClick={handleClose} />
                </div>

                <p><b>Bug:</b> <span>{currentBug.bug}</span></p>
                <p><b>Date:</b> <span>{currentBug.timestampInMillis ? moment(currentBug.timestampInMillis).format("MMM DD, YYYY hh:mm:ss A") : "-"}</span></p>

                <div id='fileBugContainer' className={`border text-center p-2`}>
                  <div className={`fade-in-out ${fadeClass}`}>
                    {filesURL ? renderFile(filesURL) : <img src={placeholderImage} className="img-fluid" style={{ maxHeight: '320px' }} alt="placeholder" />} 
                  </div>
                </div>
              </div>
            </div>
          </div>

          {total > 1 && <div className='col mt-3'>
            <div id='pagination' className='d-flex justify-content-center'>
              <Pagination count={total} page={currentIndex + 1} siblingCount={0} boundaryCount={1} size="small" shape="rounded" onChange={handleChange} />
            </div>
          </div>}
        </ModalBody>

      </Modal>
    </Fragment>
  ) : null;
};

export default BugIcon;