import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaCircleCheck, FaCircleInfo, FaCircleXmark } from 'react-icons/fa6';
import moment from 'moment';

const MissionResultRenderer = (params) => {
  const testResult = params.value;

  const getStyle = (result) => {
    switch (result) {
      case 'Normal':
        return 'text-success';
      case 'How to judge？':
        return 'text-primary';
      default:
        return 'text-danger';
    }
  };

  const getIcon = (result) => {
    switch (result) {
      case 'Normal':
        return <FaCircleCheck />;
      case 'How to judge？':
        return <FaCircleInfo />;
      default:
        return <FaCircleXmark />;
    }
  };

  return (
    <span className={getStyle(testResult)}>
      {getIcon(testResult)} {testResult}
    </span>
  );
};

const ThreeUToolsTable = memo(({ data = {} }) => {
  const [rowData, setRowData] = useState([]);
  
  const colDefs = useMemo(() => {
    return [
      { field: 'testItem', headerName: 'Test Item' },
      { field: 'factoryValue', headerName: 'Ex-factory Value' },
      { field: 'readValue', headerName: 'Read Value' },
      { field: 'testResult', headerName: 'Test Result', cellRenderer: MissionResultRenderer },
    ];
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
    };
  }, []);

  useEffect(() => {
    const newRowData = [];

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        const item = data[key];
        const testItem = key;
        const factoryValue = item.factory_value || "N/A";
        const readValue = item.current_value || "N/A";
        const testResult = item.result || "N/A";
        if (testResult !== "N/A") newRowData.push({ testItem, factoryValue, readValue, testResult });
      }
    }

    if(newRowData.length) setRowData(newRowData);
  }, [data]);

  const getTimestamp = (timestamp) => {
    const milliseconds = timestamp._seconds * 1000 + Math.floor(timestamp._nanoseconds / 1000000);
    return milliseconds;
  }

  return (
    <div className="col-12">      
      <div className="container-lg apple-card">
        <div className="row">
          <div className="col d-flex align-items-start icon-grid-item">
            <div className="content">
              <div className="d-flex align-items-center align-self-center justify-content-between">
                <h6 className="fw-bold mb-3">3uTools iDevice Verification Report</h6>
              </div>
              <p><b>Date:</b> <span>{data.timestamp ? moment(getTimestamp(data.timestamp)).format("MMM DD, YYYY") : "-"}</span></p>
              <p><b>Time:</b> <span>{data.timestamp ? moment(getTimestamp(data.timestamp)).format("hh:mm:ss A") : "-"}</span></p>

              <div className={"ag-theme-quartz"}>
                <AgGridReact rowData={rowData} columnDefs={colDefs} defaultColDef={defaultColDef} domLayout="autoHeight" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

ThreeUToolsTable.propTypes = {
  data: PropTypes.object,
};

export default ThreeUToolsTable;