import React, { memo } from 'react';
import { TbError404 } from 'react-icons/tb';
import { useRouteError } from "react-router-dom";

const ErrorPage = memo(() => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='container-fluid d-flex align-items-center justify-content-center vh-100'>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center">
          <TbError404 size={80} />
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p className="mt-3 mb-0">
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
    </div>
  )
});

export default ErrorPage;