import React, { memo, useEffect, useState } from 'react';
import '../scss/app.scss';
import { connectStorageEmulator } from 'firebase/storage';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator } from 'firebase/functions';
import { db, functions, storage } from '../firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import Report from './Report';
import Portal from './Portal';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { LinearProgress } from '@mui/material';

const App = memo(() => {
  const { loading } = useAuth();
  const [subDomain, setSubDomain] = useState(null);

  useEffect(() => {
    const { href } = window.location;

    if (href.includes('report')) {
      setSubDomain('report');
    } else if (href.includes('portal')) {
      setSubDomain('portal');
    } else {
      setSubDomain(null);
    }

    const getUrlParameters = () => {
      let params = new URLSearchParams(window.location.search);
      return params.toString();
    };

    let params = getUrlParameters();
    if (params) {
      let metaTag = document.querySelector('meta[name="apple-itunes-app"]');
      if (metaTag) {
        let baseUrl = 'https://phonetestapp.com';
        metaTag.setAttribute('content', `app-id=1553982012, app-argument=${baseUrl}?${params}`);
      }
    }

    connectEmulators(window.location.hostname);
  }, []);

  function connectEmulators(hostname) {
    if (hostname === "localhost") {
      connectFirestoreEmulator(db, process.env.REACT_APP_FIREBASE_LOCAL_HOST, 8080);
      connectStorageEmulator(storage, process.env.REACT_APP_FIREBASE_LOCAL_HOST, 9199);
      connectFunctionsEmulator(functions, process.env.REACT_APP_FIREBASE_LOCAL_HOST, 5001);
    }
  }

  let ComponentToRender;
  let heightCass = 'vh-100';

  if (subDomain === 'report') {
    heightCass = 'min-vh-100';
    ComponentToRender = <Report />;
  } else if (subDomain === 'portal') {
    ComponentToRender = <Portal />;
  } else {
    ComponentToRender = <Home />;
  }

  if(loading) return <LinearProgress />;

  return (
    <div className={`App d-flex flex-column ${heightCass}`}>
      {ComponentToRender}
      <ToastContainer />
    </div>
  );
});

export default App;
library.add(fab, fas, far);