import { useAuth } from "../context/AuthContext";
import { Fragment, useEffect, useState } from "react";
import { PiShieldWarningDuotone } from "react-icons/pi";
import HomeIcon from '@mui/icons-material/Home';
import { LinearProgress } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const [showAccessDenied, setShowAccessDenied] = useState(false);

  const goToHome = () => {
    const { href } = window.location;
    const domain = href.includes('localhost') ? "http://localhost:3000" : "https://phonetest.app";
    window.open(domain, '_self');
  };

  useEffect(() => {
    if (!loading && !user) {
      const timer = setTimeout(() => {
        setShowAccessDenied(true);
      }, 2000);
      return () => clearTimeout(timer); 
    }
  }, [loading, user]);

  if (loading) {
    return (
      <Fragment>
        <div className='container-fluid d-flex align-items-center justify-content-center vh-100'>
          <div className="text-center">
            <div className="spinner-border" role="status"></div>
            <h6 className='mt-2'>Loading...</h6>
          </div>
        </div>
      </Fragment>
    );
  }

  if (!user && showAccessDenied) {
    return (
      <Fragment>
        <div className='container-fluid d-flex align-items-center justify-content-center vh-100'>
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="text-center">
              <PiShieldWarningDuotone size={80} />
              <h1>Access Denied</h1>
              <p>You need to be logged in to access this page.</p>
              <button className="btn btn-outline-secondary" onClick={goToHome}> <HomeIcon className="me-2" /> Go To Home</button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (!user && !showAccessDenied) {
    return <LinearProgress />;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export default ProtectedRoute;