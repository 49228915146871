import React, { memo } from 'react';
import PropTypes from 'prop-types';

const LoadingComponent = memo(({ text = 'Loading...' }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        <div className="spinner-border" role="status"></div>
        <h6 className='mt-2'>{text}</h6>
      </div>
    </div>
  );
});

LoadingComponent.propTypes = {
  text: PropTypes.string,
};

export default LoadingComponent;