import React from 'react';
import ReactDOM from 'react-dom/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import ThreeUToolsModal from "./components/ThreeUToolsModal";
import moment from 'moment';

const secretKey = process.env.REACT_APP_CRYPTOJS_SECRECT_KEY;
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_CRYPTOJS_IV);

export const encrypt = (text) => {
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
};

export const decrypt = (content) => {
  const ciphertext = CryptoJS.enc.Hex.parse(content);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export function buildTooltipContent(tooltipData) {
  let innerHTML = '';
  tooltipData.forEach(item => {
    if (item.key) {
      innerHTML += `<p><span>${item.key}: </span>${item.value}</p>`;
    } else {
      innerHTML += `<p>${item.value}</p>`;
    }
  });
  return innerHTML;
}
export class CustomTooltip {
  eGui;
  init(params) {
    const eGui = (this.eGui = document.createElement('div'));
    const color = params.color || '#999';
    const tooltipData = params.tooltipData || [];

    eGui.classList.add('custom-tooltip');
    eGui.style['background-color'] = color;

    const innerHTML = buildTooltipContent(tooltipData);
    eGui.innerHTML = innerHTML;
  }

  getGui() {
    return this.eGui;
  }
}
export class ReportBtnCellRenderer {
  eGui;

  async init(params) {
    this.params = params;

    this.btnGroup = document.createElement('div');
    this.btnGroup.classList.add('btn-group', 'btn-group-sm');
    this.btnGroup.setAttribute('role', 'group');
    this.btnGroup.setAttribute('aria-label', 'Basic outlined example');

    if (!params.node.rowPinned && params.data) {
      this.openReportBtn = document.createElement('button');
      this.openReportBtn.innerHTML = `View Report`;
      this.openReportBtn.classList.add('btn', 'btn-outline-dark', 'btn-sm');

      this.openReportBtnClickedHandler = this.openReportBtnClickedHandler.bind(this);
      this.openReportBtn.addEventListener('click', this.openReportBtnClickedHandler);

      this.btnGroup.appendChild(this.openReportBtn);
    }

    this.eGui = this.btnGroup;
  }

  getGui() {
    return this.eGui;
  }

  async openReportBtnClickedHandler(event) {
    let self = this;
    const rowData = { ...self.params.data };
    const { uuid, imeis } = rowData;
    const imei = rowData.id;
    const domain = window.location.hostname.includes('localhost') ? "http://localhost:3000/report" : "https://report.phonetest.app";
    const reportURL = `${domain}/?${imeis && imeis.length ? `anon=${imei}` : `uuid=${uuid}`}`;
    window.open(reportURL, '_self');
  }

  destroy() {
    if (this.eGui) {
      if (this.openReportBtnClickedHandler) this.eGui.removeEventListener('click', this.openReportBtnClickedHandler);
    }
  }
}
export class threeUTools {
  eGui;
  params;
  modalRoot;
  modalContainer;

  async init(params) {
    this.params = params;

    this.btnGroup = document.createElement('div');
    this.btnGroup.classList.add('btn-group', 'btn-group-sm');
    this.btnGroup.setAttribute('role', 'group');
    this.btnGroup.setAttribute('aria-label', 'Basic outlined example');

    if (!params.node.rowPinned && params.data) {
      if (params.data.report && params.data.report['3uTools']) {
        this.checkIconContainer = document.createElement('div');
        this.checkIconContainer.classList.add('check-icon-container');
        this.renderCheckIcon(this.checkIconContainer, 'fa-solid fa-circle-check', 'text-success');

        this.update3uReportBtn = document.createElement('div');
        this.renderCheckIcon(this.update3uReportBtn, 'fa-solid fa-arrows-rotate');

        this.update3uReportBtn.style.display = 'none';

        this.update3uReportBtnClickedHandler = this.update3uReportBtnClickedHandler.bind(this);
        this.update3uReportBtn.addEventListener('click', this.update3uReportBtnClickedHandler);

        this.checkIconContainer.addEventListener('mouseover', () => {
          this.checkIconContainer.style.display = 'none';
          this.update3uReportBtn.style.display = 'inline-block';
        });

        this.update3uReportBtn.addEventListener('mouseout', () => {
          this.checkIconContainer.style.display = 'inline-block';
          this.update3uReportBtn.style.display = 'none';
        });

        this.btnGroup.appendChild(this.checkIconContainer);
        this.btnGroup.appendChild(this.update3uReportBtn);
      } else {
        this.get3uReportBtn = document.createElement('button');
        this.renderCheckIcon(this.get3uReportBtn, 'fa-solid fa-plus');

        this.get3uReportBtn.classList.add('btn', 'btn-outline-secondary', 'btn-sm', 'border-0');

        this.get3uReportBtnClickedHandler = this.get3uReportBtnClickedHandler.bind(this);
        this.get3uReportBtn.addEventListener('click', this.get3uReportBtnClickedHandler);

        this.btnGroup.appendChild(this.get3uReportBtn);
      }
    }

    this.eGui = this.btnGroup;
  }

  renderCheckIcon(element, icon, color = "") {
    const iconContainer = document.createElement('div');
    element.appendChild(iconContainer);

    const root = ReactDOM.createRoot(iconContainer);
    root.render(<FontAwesomeIcon icon={icon} className={color} />);
  }

  getGui() {
    return this.eGui;
  }

  get3uReportBtnClickedHandler(event) {
    const rowData = { ...this.params.data };
    this.openModal(rowData);
  }

  openModal(rowData) {
    this.modalContainer = document.createElement('div');
    document.body.appendChild(this.modalContainer);

    if (!this.modalRoot) {
      this.modalRoot = ReactDOM.createRoot(this.modalContainer);
    }
    this.modalRoot.render(<ThreeUToolsModal show={true} onHide={this.handleClose} rowData={rowData} />);
  }

  handleClose = () => {
    if (this.modalRoot) {
      this.modalRoot.unmount();
      this.modalRoot = null;
      if (this.modalContainer) {
        document.body.removeChild(this.modalContainer);
        this.modalContainer = null;
      }
    }
  };

  update3uReportBtnClickedHandler(event) {
    const rowData = { ...this.params.data };
    const { id, serial } = rowData;

    this.update3uReportBtn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="visually-hidden">Loading...</span>';
    this.update3uReportBtn.disabled = true;

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`https://us-central1-trade-apples.cloudfunctions.net/api3u?serial=${serial}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const eventData = new CustomEvent('hopla3uTools', { detail: { data, id, serial, rowData } });
        document.dispatchEvent(eventData);
        this.renderCheckIcon(this.update3uReportBtn, 'fa-solid fa-arrows-rotate');
        this.update3uReportBtn.disabled = false;
      })
      .catch((error) => {
        toast.error('Error: ' + error);
        this.renderCheckIcon(this.update3uReportBtn, 'fa-solid fa-arrows-rotate');
        this.update3uReportBtn.disabled = false;
      });
  }

  destroy() {
    if (this.eGui) {
      if (this.get3uReportBtnClickedHandler) this.eGui.removeEventListener('click', this.get3uReportBtnClickedHandler);
      if (this.update3uReportBtnClickedHandler) this.eGui.removeEventListener('click', this.update3uReportBtnClickedHandler);
    }
  }
}

export function photoVideoGradingTooltipComponentParams(params) {
  var tooltipData = [];

  if (Array.isArray(params.value) && params.value.length) {
    const timestampInMillis = params.value[params.value.length - 1].timestampInMillis;
    const formattedDate = moment(timestampInMillis).format('MMM DD, YYYY hh:mm:ss A');
    tooltipData.push({ key: 'Date', value: formattedDate });
  }

  return {
    tooltipData: tooltipData
  };
}

export function threeUToolsTooltipComponentParams(params) {
  var tooltipData = [];

  if (params.value.timestamp) {
    const timestamp = params.value.timestamp;
    const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);
    const date = moment(milliseconds);
    const formattedDate = date.format('MMM DD, YYYY hh:mm:ss A');
    tooltipData.push({ key: 'Date', value: formattedDate });
  }

  return {
    tooltipData: tooltipData
  };
}

export function processTestResults(data) {
  let passedCount = 0;
  let totalCount = 0;
  let failedTests = [];

  const excludedKeys = ["memory", "cpu", "photoVideoGrading", "storage", "imeis_by_barcode", "imeis_by_text"];

  for (const key in data) {

    if (!excludedKeys.includes(key)) {
      if (Array.isArray(data[key])) {
        const tests = data[key];
        const lastTest = tests[tests.length - 1];

        if (key === 'Built-InMicrophone') {
          const speakers = lastTest.speakers;
          if (speakers && Object.values(speakers).every(speaker =>
            Object.values(speaker.frequencies).every(freq => freq.status === true))) {
            passedCount++;
          } else {
            failedTests.push(key);
          }
          totalCount++;
        }

        else if (key === 'volume') {
          if (lastTest.up === true && lastTest.down === true) {
            passedCount++;
          } else {
            failedTests.push(key);
          }
          totalCount++;
        }

        else if (key === 'bluetooth') {
          if (lastTest.searchCapability === true) {
            passedCount++;
          } else {
            failedTests.push(key);
          }
          totalCount++;
        }

        else {
          const lastStatus = (lastTest.status !== undefined) ? lastTest.status : lastTest.state;

          if (lastStatus !== undefined) {
            if (lastStatus === true) {
              passedCount++;
            } else {
              failedTests.push(key);
            }
            totalCount++;
          }
        }

      } else {
        if (key.toLowerCase() === 'icloud') {
          if (data[key] === false) {
            passedCount++;
          } else {
            failedTests.push(key);
          }
          totalCount++;
        }
      }
    }
  }

  const summary = {
    passed: `${passedCount}/${totalCount}`,
    failed: failedTests.join(', ')
  };

  return summary;
}