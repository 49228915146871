import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import {
  BasicInformationCard,
  StorageCard,
  CpuCard,
  MemoryCard,
  ChargingPortCard,
  VibratorCard,
  FlashlightCard,
  VolumeControlCard,
  PowerCard,
  SilentCard,
  BluetoothCard,
  CellularCard,
  WifiCard,
  TouchScreenCard,
  MultiTouchCard,
  BarometerCard,
  DirectionCard,
  GyroscopeCard,
  CompassCard,
  ProximityCard,
  AuthenticationCard,
  SpeakerCard,
  CameraGrid
} from '../components/InfoCards';
import { encrypt } from '../utils';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import LoadingComponent from '../components/LoadingComponent';
import PhotoVideoGrading from '../components/PhotoVideoGrading';
import ThreeUToolsTable from '../components/ThreeUToolsTable';
import NotFoundReportComponent from '../components/NotFoundReportComponent';
import { LinearProgress } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import ManualGrading from '../components/ManualGrading';

const Report = memo(() => {
  const { loading } = useAuth();
  const [imei, setImei] = useState(null);
  const [loadingReport, setLoadingReport] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState(null);

  const showReportData = (data) => {
    setReportData(data);
    setShowReport(true);
  };

  const fetchReportData = useCallback(async () => {
    setLoadingReport(true);
    const urlParams = new URLSearchParams(window.location.search);

    const anon = urlParams.get('anon');
    const imeiParam = urlParams.get('imei');
    const uuid = urlParams.get('uuid');

    if (anon) {
      setImei(anon);
    } else if (imeiParam) {
      const encryptImei = encrypt(imeiParam);
      setImei(encryptImei);
    } else {
      setImei(uuid);
    }

    if (imei == null) return;

    const isAnonymous = anon !== null;

    try {
      const getReportDataCall = httpsCallable(functions, 'getReportDataCall');
      const data = { imei, isAnonymous };
      const result = await getReportDataCall(data);
      if (result.data && result.data.report) showReportData(result.data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setTimeout(() => {
        setLoadingReport(false);
      }, 2000);
    }
  }, [imei]);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  useEffect(() => {
    if (reportData) {
      console.log('Report Data:', reportData);
      const bugs = reportData && reportData.report && reportData.report.bugs ? reportData.report.bugs : [];
      window.bugsReport = bugs;
    }
  }, [reportData])
  

  if(loading) return <LinearProgress />;

  return (
    <Fragment>
      <HeaderComponent />

      <main className='flex-grow-1'>
        <div id="report" className="container-lg h-100 py-3">
          {loadingReport ? (
            <div className="p-5">
              <LoadingComponent text="Loading report data..." />
            </div>
          ) : showReport ? (
            <div className="row g-4 mb-3">
              <div className="col-12">
                <h4 className="border-bottom pb-3 mb-4">Report details</h4>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 g-4">
                  <BasicInformationCard basicInformation={reportData.deviceDetails?.basicInformation} />
                  <StorageCard storage={reportData.deviceDetails?.storage} />
                  <CpuCard cpu={reportData.deviceDetails?.cpu} />
                  <MemoryCard memory={reportData.deviceDetails?.memory} />
                </div>
              </div>

              <div className="col-12"><hr className="m-0" /></div>

              <div className="col-12">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 g-4">
                  <ChargingPortCard chargingPort={reportData.report.chargingPort ?? {}} threeUTools={reportData.report['3uTools']} />
                  <VibratorCard className="col" vibrator={reportData.report.vibrator ?? {}} />
                  <FlashlightCard flashlight={reportData.report.flashlight ?? {}} />
                  <VolumeControlCard volume={reportData.report.volume ?? {}} />
                  <PowerCard power={reportData.report.power ?? {}} />
                  <SilentCard silent={reportData.report.silent ?? {}} />
                  <BluetoothCard bluetooth={reportData.report.bluetooth ?? {}} />
                  <CellularCard cellular={reportData.report.cellular ?? {}} />
                  <WifiCard wifi={reportData.report.wifi ?? {}} />
                  <TouchScreenCard touchScreen={reportData.report.touchScreen ?? {}} />
                  <MultiTouchCard multiTouch={reportData.report.multiTouch ?? {}} />
                  <BarometerCard barometer={reportData.report.barometer ?? {}} />
                  <DirectionCard direction={reportData.report.direction ?? {}} />
                  <GyroscopeCard gyroscope={reportData.report.gyroscope ?? {}} />
                  <CompassCard compass={reportData.report.compass ?? {}} />
                  <ProximityCard proximity={reportData.report.proximity ?? {}} />
                  <AuthenticationCard authentication={reportData.report.authentication ?? {}} />
                </div>
              </div>

              <div className="col-12"><hr className="m-0" /></div>

              <SpeakerCard speakerData={reportData.report['Built-InMicrophone']} />

              <div className="col-12"><hr className="m-0" /></div>

              <CameraGrid report={reportData.report} />

              <div className="col-12"><hr className="m-0" /></div>

              <PhotoVideoGrading data={reportData.report['photoVideoGrading']} />
              
              { reportData.report['photoVideoGradingManual'] && <Fragment>
                <div className="col-12"><hr className="m-0" /></div>
                <ManualGrading data={reportData.report['photoVideoGradingManual']} /> 
              </Fragment> }

              <ThreeUToolsTable data={reportData.report['3uTools']} />
            </div>
          ) : (
            <Fragment>
              {imei && <NotFoundReportComponent imei={imei} />}
            </Fragment>
          )}
        </div>
      </main>

      <FooterComponent />
    </Fragment>
  )
});

export default Report;