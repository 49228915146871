import React, { Fragment } from 'react';
import logo from '../img/logos/logo.png';
import AppStoreBadgeUS from '../img/App_Store_Badge_US.svg';
import MetaTagComponent from '../components/MetaTagComponent.jsx';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { useCookies } from 'react-cookie';
import { useAuth } from '../context/AuthContext.js';
import { GoogleAuthProvider } from 'firebase/auth';
import HeaderComponent from '../components/HeaderComponent.jsx';
import { LinearProgress } from '@mui/material';

const Home = () => {
  const { user, loading, loginWithGoogle } = useAuth();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['hoplaCredentials']);

  const redirectPortal = () => {
    const { href } = window.location;
    const domain = href.includes('localhost') ? "http://localhost:3000/portal" : "https://portal.phonetest.app";
    window.location.href = domain;
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle().then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const userDataString = JSON.stringify(credential);
        setCookie('hoplaCredentials', userDataString);
        redirectPortal();
      });
    } catch (error) {
      removeCookie('hoplaCredentials');
      console.error("Error signing in with Google: ", error);
    }
  };

  if(loading) return <LinearProgress />;
  
  return (
    <Fragment>
      <MetaTagComponent />

      { user && <HeaderComponent /> }

      <div className='container-fluid bg-dark d-flex align-items-center justify-content-center vh-100 position-fixed'>
        <div className="row g-3">
          <div className="col-12 text-center">
            <img src={logo} className="App-logo" alt="logo" width="256px" />
            <h1 className="text-white">PhoneTest.App</h1>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-2">
            <a href="https://apps.apple.com/us/app/phonetest-app/id1553982012">
              <img src={AppStoreBadgeUS} alt="Buttom Logo" height="48px" />
            </a>

            { !user && <div><GoogleLoginButton className='rounded-3' size='48px' onClick={handleGoogleLogin} /></div> }

          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
