import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

const ThreeUToolsModal = ({ show, onHide, rowData }) => {
  const [serial, setSerial] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!serial) {
      toast.error('Provide a valid serial');
      return;
    }

    setIsLoading(true);

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://us-central1-trade-apples.cloudfunctions.net/api3u?serial=${serial}`, requestOptions);
      const data = await response.json();
      
      const eventData = new CustomEvent('hopla3uTools', { detail: { data, id: rowData.id, serial, rowData } });
      document.dispatchEvent(eventData);

      setIsLoading(false);
      onHide();
    } catch (error) {
      toast.error('Error: ' + error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{`${rowData.deviceDetails.basicInformation.model} ${rowData.deviceDetails.storage.total}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Enter the device serial number to get the report.</Form.Label>
              <Form.Control
                type="text"
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                required
                disabled={isLoading}
              />
            </Form.Group>
            <div className="d-flex justify-content-end mt-3">
              <Button 
                variant="secondary" 
                onClick={onHide} 
                disabled={isLoading} 
                className="me-2 btn-sm">
                Cancel
              </Button>
              <Button 
                variant="primary" 
                type="submit" 
                disabled={isLoading} 
                className='btn-sm'>
                {isLoading ? 'Loading...' : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ThreeUToolsModal;