import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const NotFoundReportComponent = memo(({ imei = 'imei' }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-bg-light text-center border border-2 rounded-4 p-4">
        <ContentPasteSearchIcon sx={{ fontSize: 80 }} />
        <p className="mt-3 mb-0">No report found for <b id="imei-anon">{imei}</b></p>
      </div>
    </div>
  )
});

NotFoundReportComponent.propTypes = {
  imei: PropTypes.string,
};

export default NotFoundReportComponent;