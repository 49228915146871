import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss';
import { CookiesProvider } from 'react-cookie';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './components/ErrorPage';
import Report from './routes/Report';
import Portal from './routes/Portal';
import App from './routes/App';
import { AuthProvider } from './context/AuthContext';
import 'react-tooltip/dist/react-tooltip.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "report",
        element: <Report />,
      },
      {
        path: "portal",
        element: <Portal />,
      }
    ],
  },
]);

const defaultSetOptions = {
  path: '/',
  domain: '.phonetest.app' 
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <CookiesProvider defaultSetOptions={defaultSetOptions}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </CookiesProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
