import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ref, updateMetadata, getDownloadURL, getMetadata } from 'firebase/storage';
import { storage } from '../firebase';
import ManualGrading from './ManualGrading';
import placeholderImage from '../img/placeholder-image.webp';

const fetchPhotoVideoGrading = async (filePath) => {
  const baseFilePath = `cosmetic_test/${filePath}/`;

  const fileNames = [
    'COSMETIC_TEST_PHOTO_Screen_ON.jpg',
    'COSMETIC_TEST_PHOTO_Screen_OFF.jpg',
    'COSMETIC_TEST_PHOTO_Left.jpg',
    'COSMETIC_TEST_PHOTO_Top.jpg',
    'COSMETIC_TEST_PHOTO_Right.jpg',
    'COSMETIC_TEST_PHOTO_Bottom.jpg',
    'COSMETIC_TEST_PHOTO_Back.jpg',
    'COSMETIC_TEST_PHOTO_Cameras.jpg',
    'COSMETIC_TEST_VIDEO.mov'
  ];

  const results = [];

  for (const fileName of fileNames) {
    const fullPath = `${baseFilePath}${fileName}`;
    const fileRef = ref(storage, fullPath);

    try {
      await getMetadata(fileRef);

      const metadata = {
        metadata: {
          customMetadata: {
            'public': 'true'
          }
        }
      };

      await updateMetadata(fileRef, metadata);

      const url = await getDownloadURL(fileRef);

      let name = fileName.replace('COSMETIC_TEST_PHOTO_', '');
      name = name.replace('COSMETIC_TEST_VIDEO', 'Video');
      name = name.replace('_', ' ');
      name = name.replace('.jpg', '');
      name = name.replace('.mov', '');
      const type = name === "Video" ? 'video' : '';
      results.push({ name, url, type });
    } catch (error) {
      // console.error(`Error checking existence, updating metadata, or getting public URL for ${fileName}:`, error);

      let name = fileName.replace('COSMETIC_TEST_PHOTO_', '');
      name = name.replace('COSMETIC_TEST_VIDEO', 'Video');
      name = name.replace('_', ' ');
      name = name.replace('.jpg', '');
      name = name.replace('.mov', '');
      const type = name === "Video" ? 'video' : '';
      results.push({ name, url: placeholderImage, type });
    }
  }

  return results;
};

const generateFileURLs = async (data) => {
  const newData = await Promise.all(
    data.map(async (element) => {
      const path = element.path;
      const files = await fetchPhotoVideoGrading(path);
      return { ...element, files, mode: "auto" };
    })
  );

  return newData;
};

const PhotoVideoGrading = memo(({ data = [] }) => {
  const [cosmeticGrading, setCosmeticGrading] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await generateFileURLs(data);
      setCosmeticGrading(newData);
    };
    fetchData();
  }, [data]);

  return (
    <ManualGrading data={cosmeticGrading} />
  );
});

PhotoVideoGrading.propTypes = {
  data: PropTypes.array,
};

export default PhotoVideoGrading;