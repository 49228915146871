import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import { FaFilter } from 'react-icons/fa6';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from 'moment';
import { CustomTooltip, photoVideoGradingTooltipComponentParams, processTestResults, ReportBtnCellRenderer, threeUTools, threeUToolsTooltipComponentParams } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, doc, onSnapshot, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import ProtectedRoute from '../components/ProtectedRoute';
import { useAuth } from '../context/AuthContext';
import { LinearProgress } from '@mui/material';

const Portal = () => {
  const { loading } = useAuth();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['hoplaCredentials']);
  const [rowData, setRowData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const colDefs = useMemo(() => {
    return [
      {
        headerName: 'IMEI',
        field: 'id',
        pinned: 'left',
        minWidth: 180,
        valueFormatter: params => {
          if (params.node.rowPinned) return params.value ?? '';
          const { id, uuid, imeis } = params.data;
          const value = imeis && imeis.length ? imeis[0] : uuid ?? id ?? "";
          if (typeof value !== 'string') return value.toString();
          return value;
        },
      },
      { headerName: 'Model', field: 'deviceDetails.basicInformation.model' },
      { headerName: 'Storage', field: 'deviceDetails.storage.total' },
      {
        headerName: "Passed",
        field: "result.passed"
      },
      {
        headerName: "Failed",
        field: "result.failed",
        minWidth: 300
      },
      {
        headerName: 'Last User Scan',
        field: 'lastReport',
        valueFormatter: params => {
          if (params.node.rowPinned) return params.value ?? '';
          const timestamp = params.value;
          const formattedDate = moment(timestamp).format('MMM DD, YYYY hh:mm A');
          return formattedDate;
        },
        sort: 'desc',
        minWidth: 200
      },
      {
        headerName: '3u',
        field: 'report.3uTools',
        cellClass: 'hopla-actions-cell',
        cellStyle: { textAlign: 'center' },
        tooltipField: 'report.3uTools',
        tooltipComponentParams: params => threeUToolsTooltipComponentParams(params),
        cellRenderer: threeUTools,
      },
      {
        headerName: "Cosmetic Grading",
        field: 'report.photoVideoGrading',
        cellStyle: { textAlign: 'center' },
        tooltipField: 'report.photoVideoGrading',
        tooltipComponentParams: params => photoVideoGradingTooltipComponentParams(params),
        cellRenderer: params => {
          if (params.node.rowPinned) return params.value ?? '';
          if (Array.isArray(params.value) && params.value.length) {
            return <FontAwesomeIcon icon="fa-solid fa-circle-check" className="text-success" />;
          }
          return "";
        }
      },
      {
        headerName: 'Actions',
        minWidth: 120,
        cellClass: 'hopla-actions-cell',
        cellStyle: { textAlign: 'center' },
        cellRenderer: ReportBtnCellRenderer,
        pinned: 'right'
      }
    ];
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      cellDataType: false,
      tooltipComponent: CustomTooltip,
    };
  }, []);

  const autoSizeStrategy = {
    type: 'fitCellContents',
    defaultMinWidth: 100,
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
    width: 512,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  
  const handleAuthStateChanged = useCallback(async (user) => {
    const credential = cookies.hoplaCredentials;
        
    if (user && credential) {
      setCurrentUser(user);
      const uid = user.uid;
      const reportsRef = collection(db, 'reports');
      const reportsQuery = query(reportsRef, where("usersId", "array-contains", uid));

      onSnapshot(reportsQuery, (snapshot) => {
        const reports = snapshot.docs.map(doc => {
          const data = doc.data();
          const userInArray = data.users.find(user => user.uid === uid) || {};
          const lastReport = userInArray.lastReport ?? "";

          delete data.userUids;
          delete data.users;
          delete data.queryParameters;

          return {
            id: doc.id,
            lastReport,
            ...data,
            result: processTestResults(data.report)
          };
        })
        .filter(report => Array.isArray(report.imeis) && report.imeis.length > 0);

        setRowData(reports);
      }, (error) => {
        const err = "Error fetching reports: " + error;
        console.error(err);
        Toast.fire({ icon: "error", title: err });
      });
    } else {
      setRowData([]);
      setCurrentUser(null);
    }

    setAuthChecked(true);

  // eslint-disable-next-line
  }, [cookies]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleAuthStateChanged(user);
    });

    return () => unsubscribe();
  }, [handleAuthStateChanged]);

  async function update3uTools(imei, serial, data) {
    try {
      const docRef = doc(db, "reports", imei);
      data.timestamp = serverTimestamp();
      const report = {
        "3uTools": data
      }
      await setDoc(docRef, { report, serial }, { merge: true });
      const msg = `<b>3uTools</b> report updated.`;
      Toast.fire({ icon: "success", title: msg });
    } catch (error) {
      const err = "Error updating document: " + error;
      console.error(err);
      Toast.fire({ icon: "error", title: err });
    }
  }

  useEffect(() => {
    const handleHopla3uToolsEvent = (event) => {
      const { data, id, serial, rowData } = event.detail;
      console.log("Data: ", data);

      if (data.error) {
        Toast.fire({ icon: "error", title: data.error });
      } else {
        const deviceModel = data['Device Model'] ?? data['Device Model11111'] ?? {};
        if (deviceModel && deviceModel.current_value === rowData.deviceDetails.basicInformation.model) {
          update3uTools(id, serial, data);
        } else {
          const err = 'It seems that the entered serial number does not correspond to the selected device.';
          console.error(err);
          Toast.fire({ icon: "error", title: err });
        }
      }
    };

    document.addEventListener('hopla3uTools', handleHopla3uToolsEvent);

    return () => {
      document.removeEventListener('hopla3uTools', handleHopla3uToolsEvent);
    };
    // eslint-disable-next-line
  }, []);

  const autoSizeAll = useCallback(() => {
    if (gridRef.current && gridRef.current.columnApi) {
      const allColumnIds = [];
      gridRef.current.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds);
    }

    setTimeout(() => { autoSizeAll(); }, 1000);
  }, []);

  useEffect(() => {
    if (rowData.length > 0) {
      autoSizeAll();
    }
  }, [rowData, autoSizeAll]);

  if (!authChecked) {
    return null;
  }

  if(loading) return <LinearProgress />;

  return (
    <ProtectedRoute>
      <HeaderComponent />

      <main className='p-3 flex-grow-1'>

        { currentUser &&
          <div className="container-lg" style={containerStyle}>
            <div className="outer-div">
              <div className="border bg-light p-3">
                <div className="row g-3">
                  <div className="col-12 col-md-8 col-lg col-xl-5 col-xxl-5 me-auto">
                    <div className="input-group">
                      <span className="input-group-text bg-white" id="filter-drafts-table">
                        <FaFilter />
                      </span>
                      <input id="filterReportsTable" type="search" className="form-control searchInput"
                        placeholder="Value to filter" aria-label="Value to filter" aria-describedby="filter-drafts-table" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-wrapper">
                <div style={gridStyle} className={"ag-theme-alpine"}>
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    domLayout="normal"
                    tooltipShowDelay={500}
                    tooltipHideDelay={20000}
                    tooltipMouseTrack
                    autoSizeStrategy={autoSizeStrategy}
                  />
                </div>
              </div>
            </div>

          </div>
        }
      </main>

      <FooterComponent />
    </ProtectedRoute>
  )
};

export default Portal;